import React, { Component, createRef } from 'react';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import './Phone.css';
// import './MasterForm.css';
import { DisplayPhone } from './DisplayPhone';

export class MasterFormCreate extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      email: '',
      username: '',
      password: '',
      typecard: 'Professional',
      prefix: 'Mr',
      firstName: '',
      lastName: '',
      inputPhone: '',
      inputEmail: '',
      inputFax: '',
      workEmail: [],
      workPhone: [],
      workFax: [],
      organization: '',
      title: '',
      workurl: '',
      workaddrlabel: '',
      workaddrstreet: '',
      workaddrcity: '',
      workaddrzipcode: '',
      workaddrcountry: '',
      facebook: '',
      linkedin: '',
      twitter: '',
      instagram: '',
      pinterest: '',
      youtube: '',
      file: null,
    };

    this.token = localStorage.getItem('sctu');
    this.canvas = createRef();
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.handelChangeName = this.handelChangeName.bind(this);
    this.handelChangeInc = this.handelChangeInc.bind(this);
    this.handelChangeDec = this.handelChangeDec.bind(this);
  }
  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep;
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }
  // The "next" and "previous" button functions
  previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-secondary"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    // ...else return nothing
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 3) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      );
    }
    // ...else render nothing
    return null;
  }

  handelChangeName(name, value) {
    //console.log('test' + name + ':' + value);
    this.setState({
      [name]: value,
    });
  }
  handelChangeInc(id, valInc) {
    if (id === 'tel') {
      if (valInc !== '') {
        let workPhone = this.state.workPhone;
        workPhone.push(valInc);
        //console.log(workPhone);
        this.setState({
          workPhone,
          inputPhone: '',
        });
      }
    }
    if (id === 'fax') {
      if (valInc !== '') {
        let workFax = this.state.workFax;
        workFax.push(valInc);
        this.setState({
          workFax,
          inputFax: '',
        });
      }
    }
    if (id === 'email') {
      if (valInc !== '') {
        let workEmail = this.state.workEmail;
        workEmail.push(valInc);
        this.setState({
          workEmail,
          inputEmail: '',
        });
      }
    }
  }

  handelChangeDec(event) {
    if (event.currentTarget.className === 'dec_button') {
      if (event.currentTarget.id === 'tel') {
        let workPhone = this.state.workPhone;
        workPhone.pop();
        this.setState({
          workPhone,
        });
      }
      if (event.currentTarget.id === 'email') {
        let workEmail = this.state.workEmail;
        workEmail.pop();
        this.setState({
          workEmail,
        });
      }
      if (event.currentTarget.id === 'fax') {
        let workFax = this.state.workFax;
        workFax.pop();
        this.setState({
          workFax,
        });
      }
    }
  }
  // Use the submitted data to set the state
  handleChange(event) {
    //console.log(event.currentTarget.className);
    if (event.currentTarget.className === 'select-title') {
      this.setState({ prefix: event.target.value });
    }
    if (event.target.className === 'file') {
      this.setState({
        file: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

  resizeImage = async () => {
    const image = new Image();
    image.src = this.state.file;
    let canvas = this.canvas.current;
    let context = canvas.getContext('2d');
    let widthImg = image.width;
    let heightImg = image.height;
    if (widthImg > 300) {
      widthImg = 300;
    }
    if (heightImg > 300) {
      heightImg = 300;
    }
    canvas.width = widthImg;
    canvas.height = heightImg;
    context.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    ////console.log(canvas.toDataURL('image/jpg'))
    const convertBase64ToFile = function (image) {
      const byteString = atob(image.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      const newBlob = new Blob([ab], {
        type: 'image/jpeg',
      });
      return newBlob;
    };
    return convertBase64ToFile(canvas.toDataURL());
  };

  pic_profil = async (event) => {
    event.preventDefault();
    let url = process.env.REACT_APP_URL + '/api/upload/addimageuser';
    const UrlBase64 = await this.resizeImage(event);
    let input = await new File([UrlBase64], 'imageResize.jpg');
    if (input.size !== 0) {
      let data = new FormData();
      await data.append('0', input);
      //  await data.append('1', this.props.iduser);
      const response = await fetch(url, {
        method: 'POST',
        body: data,
        headers: { Authorization: 'bearer ' + this.token },
      });
      // await //console.log(response);
      if (response.ok) {
        const json = await response.json();
        return json.photoimport;
      } else {
        return '';
      }
    }
  };
  // createQrcode = async function (idvcard) {
  //   let url =  process.env.REACT_APP_URL + '/api/qrcode/createqrcode';
  //   //console.log('creeee : ', idvcard);
  //   let id_type = idvcard;
  //   let shape = 'circle';
  //   let color_light = '#FFFFFF';
  //   let color_dark = '#000000';
  //   let logo = null;
  //   let type = 'vcard';
  //   const response = await fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       Authorization: 'bearer ' + this.token,
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       id_type,
  //       shape,
  //       color_light,
  //       color_dark,
  //       logo,
  //       type,
  //     }),
  //   });
  //   //  await //console.log(response);
  //   if (response.ok) {
  //     const json = await response.json();
  //     return json;
  //   } else {
  //     const json = await response.json();
  //     return json;
  //   }
  // };
  // Trigger an alert on form submission
  handleSubmit = async (event) => {
    event.preventDefault();
    let photoimport = '';
    let input = this.state.file;
    if (input !== null) {
      //console.log('input ok');
      const resup = await this.pic_profil(event);
      photoimport = await resup;
    } else {
      //console.log('input empty');
    }
    ///
    const apiupdate = process.env.REACT_APP_URL + '/api/card/createcard';

    console.log(this.state);
    let prefix = this.state.prefix;
    let firstname = this.state.firstName;
    let lastname = this.state.lastName;
    let organization = this.state.organization;
    let title = this.state.title;
    let workurl = this.state.workurl;
    let workaddrlabel = this.state.workaddrlabel;
    let workaddrstreet = this.state.workaddrstreet;
    let workaddrcity = this.state.workaddrcity;
    let workaddrzipcode = this.state.workaddrzipcode;
    let workaddrcountry = this.state.workaddrcountry;
    let workphone = this.state.workPhone;
    let workfax = this.state.workFax;
    let workemail = this.state.workEmail;
    let facebook = this.state.facebook;
    let linkedin = this.state.linkedin;
    let twitter = this.state.twitter;
    let instagram = this.state.instagram;
    let pinterest = this.state.pinterest;
    let youtube = this.state.youtube;
    let note = '';
    let id_user = this.props.iduser;
    let name_card = 'test';

    try {
      //console.log(this.token);
      const response = await fetch(apiupdate, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + this.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_user,
          name_card,
          prefix,
          firstname,
          lastname,
          workphone,
          workemail,
          workfax,
          organization,
          title,
          workurl,
          workaddrlabel,
          workaddrstreet,
          workaddrcity,
          workaddrzipcode,
          workaddrcountry,
          facebook,
          linkedin,
          twitter,
          instagram,
          pinterest,
          youtube,
          note,
          photoimport,
        }),
      });
      // await //console.log(response);
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        window.location.assign('/cards?id=' + this.props.iduser);
      } else {
        const json = await response.json();
        console.log(json);
      }
    } catch (err) {
      console.error(err);
      //console.log('JWT');
      //window.location.assign('/signin');
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className=" pl-5-model">
          <div className="row justify-content-center">
            <div className="col-md-3">
              <h1 className="text-center mtop-title">Create Virtual Card</h1>
              <div className="result"></div>
            </div>
          </div>
          <p className="text-center w-responsive mx-auto mb-4">
            Create your professional Virtual Card
          </p>

          <div className="row">
            <div className="col-md-8 mb-md-0 mb-5">
              <h3>Step: {this.state.currentStep}/3</h3>
              <form onSubmit={this.handleSubmit}>
                <Step1
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  handelChangeName={this.handelChangeName}
                  prefix={this.state.prefix}
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                  file={this.state.file}
                  organization={this.state.organization}
                  title={this.state.title}
                  workurl={this.state.workurl}
                />
                <Step2
                  currentStep={this.state.currentStep}
                  handelChangeInc={this.handelChangeInc}
                  handelChangeDec={this.handelChangeDec}
                  handelChangeName={this.handelChangeName}
                  inputPhone={this.state.inputPhone}
                  inputEmail={this.state.inputEmail}
                  inputFax={this.state.inputFax}
                  workEmail={this.state.workEmail}
                  workFax={this.state.workFax}
                  workPhone={this.state.workPhone}
                  workaddrlabel={this.state.workaddrlabel}
                  workaddrcity={this.state.workaddrcity}
                  workaddrstreet={this.state.workaddrstreet}
                  workaddrzipcode={this.state.workaddrzipcode}
                  workaddrcountry={this.state.workaddrcountry}
                />
                <Step3
                  currentStep={this.state.currentStep}
                  handelChangeName={this.handelChangeName}
                  facebook={this.state.facebook}
                  twitter={this.state.twitter}
                  instagram={this.state.instagram}
                  pinterest={this.state.pinterest}
                  linkedin={this.state.linkedin}
                  youtube={this.state.youtube}
                />
                {this.previousButton()}
                {this.nextButton()}
              </form>
            </div>
            <div className="col-md-4 mb-md-0 mb-5">
              <DisplayPhone
                prefix={this.state.prefix}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                workEmail={this.state.workEmail}
                workFax={this.state.workFax}
                workPhone={this.state.workPhone}
                file={this.state.file}
                organization={this.state.organization}
                title={this.state.title}
                workurl={this.state.workurl}
                workaddrlabel={this.state.workaddrlabel}
                workaddrstreet={this.state.workaddrstreet}
                workaddrcity={this.state.workaddrcity}
                workaddrzipcode={this.state.workaddrzipcode}
                workaddrcountry={this.state.workaddrcountry}
                facebook={this.state.facebook}
                twitter={this.state.twitter}
                instagram={this.state.instagram}
                pinterest={this.state.pinterest}
                linkedin={this.state.linkedin}
                youtube={this.state.youtube}
              />
            </div>
          </div>
          <canvas ref={this.canvas}></canvas>
        </div>
      </React.Fragment>
    );
  }
}
