import React, { useRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import './popupEditCustomerList.css';
import DatePicker from 'react-datepicker';
import { Formik } from 'formik';

const PopupEdit = (props) => {
  const token = localStorage.getItem('sctu');
  const [oldmail] = useState(props.infoCustomer.email);
  const [dateEndContract, setDateEndContract] = useState(new Date());
  const [email, setEmail] = useState(props.infoCustomer.email);
  const [firstname, setFirstname] = useState(props.infoCustomer.firstname);
  const [lastname, setLastname] = useState(props.infoCustomer.lastname);

  useEffect(async () => {
    setDateEndContract(new Date(props.infoCustomer.end_contract));
  }, []);

  const ValidateEmail = function (mail) {
    let myValue = mail.replace(/[^a-z0-9.@\-_]/gi, '');
    setEmail(myValue);
  };

  const UpdateProfile = async () => {
    const updateprofile =
      process.env.REACT_APP_URL + '/api/clients/updateclient';

    try {
      const enddate = moment(dateEndContract).format('YYYY-MM-DD');
      const response = await fetch(updateprofile, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token,
        },
        body: JSON.stringify({
          oldemail: oldmail,
          firstname: firstname,
          lastname: lastname,
          newemail: email,
          endcontract: enddate,
        }),
      });
      if (response.ok) {
        const json = await response.json();
        //  localStorage.setItem('sctu', json.token);
        console.log(json);
        window.location.assign('/profile');
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="popup">
      <div className="popup_inner">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row row-popup">
          <div className="col-5">
            <div className="not-form-group">
              <label> ID User : </label>
              <p className="not-form">{props.infoCustomer.id}</p>
            </div>
            <div className="not-form-group">
              <label> Creation date </label>
              <p className="not-form">
                {moment(props.infoCustomer.creation).format('DD/MM/YYYY')}
              </p>
            </div>
            <div className="not-form-group">
              <label> Last Payment </label>
              <p className="not-form">
                {moment(props.infoCustomer.lastpayment).format('DD/MM/YYYY')}
              </p>
            </div>
            <div className="not-form-group">
              <label> SumUp Forfait </label>
              <div className="not-form">
                Name forfait : {props.infoCustomer.nameforfait}
              </div>
              <div className="not-form">
                Url used: {props.infoCustomer.nburlused}/{' '}
                {props.infoCustomer.numberurl}
              </div>
              <div className="not-form">
                Cards used : {props.infoCustomer.nbvcardused}/
                {props.infoCustomer.numbercvf}
              </div>
            </div>
          </div>
          <div className="col-5">
            <form>
              <div className="form-group">
                <label>Email address</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => ValidateEmail(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label>End Contract</label>
                <div className="form-control">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={dateEndContract}
                    onChange={(date) => setDateEndContract(date)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>FirstName</label>
                <input
                  type="firstname"
                  className="form-control"
                  value={firstname}
                  onChange={(e) => setFirstname(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label>LastName</label>
                <input
                  type="lastname"
                  className="form-control"
                  value={lastname}
                  onChange={(e) => setLastname(e.currentTarget.value)}
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() => UpdateProfile()}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupEdit;
