import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Sliders as SlidersIcon,
  CreditCard as CreditCardIcon,
} from 'react-feather';
import NavItem from './NavItem';

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
  },
  {
    href: '/customers',
    icon: UsersIcon,
    title: 'Customers',
  },
  {
    href: '/products',
    icon: ShoppingBagIcon,
    title: 'Cards and URL Customers',
  },
  {
    href: '/package',
    icon: SlidersIcon,
    title: 'Package URL and Cards',
  },
  {
    href: '/pricing',
    icon: CreditCardIcon,
    title: 'Princing',
  },
  {
    href: '/settings',
    icon: SettingsIcon,
    title: 'LOGO QRCODE',
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const token = localStorage.getItem('sctu');
  const [user, setUser] = useState({
    creation: '',
    email: '',
    firstname: '',
    lastname: '',
    name: '',
    avatar: '/static/images/avatars/avatar_6.png',
  });
  const InfoAdmin = async () => {
    const url = process.env.REACT_APP_URL + '/api/clients/infoadmin';
    ////console.log(url);
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();
      setUser({
        creation: json.creation,
        email: json.email,
        firstname: json.firstname,
        lastname: json.lastname,
        name: json.name,
        avatar: json.avatar_url,
      });
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    InfoAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.firstname} {user.lastname}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.name}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item, i) => (
            <NavItem
              key={i}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
