import React, { useState, useEffect } from 'react';
// import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
// import CustomerListView from './CustomerListView/index';
// import ProductList from './ProductListView/index';
import Toolbar from './CardList/Toolbar';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const CardsListPage = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [allValues, setAllValues] = useState({
    cards: [],
    // numberLimitURL: '',
  });
  const token = localStorage.getItem('sctu');
  const Info = async () => {
    let url_string = window.location.href;
    var st = new URL(url_string);
    var id = st.searchParams.get('id');
    const url = process.env.REACT_APP_URL + '/api/card/info?id=' + id;
    ////console.log(url);
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();
      setAllValues({
        cards: json.cards,
        //   numberLimitURL: parseInt(json.nburl.numberurl),
      });
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  useEffect(async () => {
    async function fetchMyAPI() {
      let response = await Info();
    }
    fetchMyAPI();
  }, []);
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Toolbar cards={allValues.cards} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsListPage;
