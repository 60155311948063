import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
import AddTaskForm from './AddItems';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Trash2 as TrashIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const PopupUpdateForfait = (props) => {
  const classes = useStyles();
  const token = localStorage.getItem('sctu');
  const [listFlateRate, setListFlateRate] = useState([]);

  const [priceData, setpriceData] = useState({
    id: 0,
    reference_id: '',
    frequency: 'months',
    nb_frequency: 0,
    id_flaterate: 0,
    description: '',
    custom_id: '',
    soft_descriptor: '',
    currency_code: 'EUR',
    item_total_price: 0,
    tax_total_price: 0,
    shipping_discount: 0,
    items: [],
  });
  const [tasksItems, setTasksItems] = useState(props.prices.items);

  const addTask = (datacat) => {
    let newList = [...tasksItems, datacat];
    setTasksItems(newList);
    handleTotalPriceAndItemsDetail(newList);
  };

  const removeTask = (index) => {
    console.log(index);
    const newTasks = [...tasksItems];
    newTasks.splice(index, 1);
    setTasksItems(newTasks);
    handleTotalPriceAndItemsDetail(newTasks);
  };
  useEffect(() => {
    const asynfonction = async () => {
      await setListFlateRate(props.flaterate);
      await setpriceData({
        id: props.prices.id,
        reference_id: props.prices.reference_id,
        frequency: props.prices.frequency,
        nb_frequency: props.prices.nb_frequency,
        id_flaterate: props.prices.id_flaterate,
        description: props.prices.description,
        custom_id: props.prices.custom_id,
        soft_descriptor: props.prices.soft_descriptor,
        currency_code: props.prices.currency_code,
        item_total_price: props.prices.item_total_price,
        tax_total_price: props.prices.tax_total_price,
        shipping_discount: props.prices.shipping_discount,
        items: props.prices.items,
      });
    };
    asynfonction();
    //  console.log(props.flaterate);
  }, []);
  const [frequencyType, setfrequencyType] = useState(['months', 'year']);
  const Addfrequency = frequencyType.map((Addfrequency) => Addfrequency);
  const AddFlatRate = listFlateRate.map((AddFlatRate) => AddFlatRate);

  const handleRefIDAndCustomID = async (event) => {
    let ref = event.target.value.replace(/[^a-zA-Z-]/gi, '').toUpperCase();
    await setpriceData({
      id: priceData.id,
      reference_id: ref,
      frequency: priceData.frequency,
      nb_frequency: priceData.nb_frequency,
      id_flaterate: priceData.id_flaterate,
      description: priceData.description,
      custom_id: 'CUST-' + ref,
      soft_descriptor: priceData.soft_descriptor,
      currency_code: priceData.currency_code,
      item_total_price: priceData.item_total_price,
      tax_total_price: priceData.tax_total_price,
      shipping_discount: priceData.shipping_discount,
      items: priceData.items,
    });
  };

  const handleFrequency = async (event) => {
    await setpriceData({
      id: priceData.id,
      reference_id: priceData.reference_id,
      frequency: frequencyType[event.target.value],
      nb_frequency: priceData.nb_frequency,
      id_flaterate: priceData.id_flaterate,
      description: priceData.description,
      custom_id: priceData.custom_id,
      soft_descriptor: priceData.soft_descriptor,
      currency_code: priceData.currency_code,
      item_total_price: priceData.item_total_price,
      tax_total_price: priceData.tax_total_price,
      shipping_discount: priceData.shipping_discount,
      items: priceData.items,
    });
  };
  const handleNBFrequency = async (event) => {
    await setpriceData({
      id: priceData.id,
      reference_id: priceData.reference_id,
      frequency: priceData.frequency,
      nb_frequency: event.target.value,
      id_flaterate: priceData.id_flaterate,
      description: priceData.description,
      custom_id: priceData.custom_id,
      soft_descriptor: priceData.soft_descriptor,
      currency_code: priceData.currency_code,
      item_total_price: priceData.item_total_price,
      tax_total_price: priceData.tax_total_price,
      shipping_discount: priceData.shipping_discount,
      items: priceData.items,
    });
  };

  const handleDescription = async (event) => {
    await setpriceData({
      id: priceData.id,
      reference_id: priceData.reference_id,
      frequency: priceData.frequency,
      nb_frequency: priceData.nb_frequency,
      id_flaterate: priceData.id_flaterate,
      description: event.target.value,
      custom_id: priceData.custom_id,
      soft_descriptor: priceData.soft_descriptor,
      currency_code: priceData.currency_code,
      item_total_price: priceData.item_total_price,
      tax_total_price: priceData.tax_total_price,
      shipping_discount: priceData.shipping_discount,
      items: priceData.items,
    });
  };

  const handleIDFlateRate = async (event) => {
    let flateId = event.target.value;

    await setpriceData({
      id: priceData.id,
      reference_id: priceData.reference_id,
      frequency: priceData.frequency,
      nb_frequency: priceData.nb_frequency,
      id_flaterate: parseFloat(flateId),
      description: priceData.description,
      custom_id: priceData.custom_id,
      soft_descriptor: priceData.soft_descriptor,
      currency_code: priceData.currency_code,
      item_total_price: priceData.item_total_price,
      tax_total_price: priceData.tax_total_price,
      shipping_discount: priceData.shipping_discount,
      items: priceData.items,
    });
  };
  const handleSoftDescriptor = async (event) => {
    await setpriceData({
      id: priceData.id,
      reference_id: priceData.reference_id,
      frequency: priceData.frequency,
      nb_frequency: priceData.nb_frequency,
      id_flaterate: priceData.id_flaterate,
      description: priceData.description,
      custom_id: priceData.custom_id,
      soft_descriptor: event.target.value,
      currency_code: priceData.currency_code,
      item_total_price: priceData.item_total_price,
      tax_total_price: priceData.tax_total_price,
      shipping_discount: priceData.shipping_discount,
      items: priceData.items,
    });
  };
  const handleTotalPriceAndItemsDetail = async (newList) => {
    let sum = await newList.reduce(function (total, currentValue) {
      return (
        total +
        Math.round(
          (parseFloat(currentValue.quantity) *
            parseFloat(currentValue.unit_amount.value) *
            100) /
            100
        )
      );
    }, 0);
    await newList.map(function (x, index) {
      x.sku = 'sku' + (index + 1).toString();
      return x;
    });
    await setpriceData({
      id: priceData.id,
      reference_id: priceData.reference_id,
      frequency: priceData.frequency,
      nb_frequency: priceData.nb_frequency,
      id_flaterate: priceData.id_flaterate,
      description: priceData.description,
      custom_id: priceData.custom_id,
      soft_descriptor: priceData.soft_descriptor,
      currency_code: priceData.currency_code,
      item_total_price: sum.toString(),
      tax_total_price: priceData.tax_total_price,
      shipping_discount: priceData.shipping_discount,
      items: newList,
    });
  };
  const handleTax = async (event) => {
    await setpriceData({
      id: priceData.id,
      reference_id: priceData.reference_id,
      frequency: priceData.frequency,
      nb_frequency: priceData.nb_frequency,
      id_flaterate: priceData.id_flaterate,
      description: priceData.description,
      custom_id: priceData.custom_id,
      soft_descriptor: priceData.soft_descriptor,
      currency_code: priceData.currency_code,
      item_total_price: priceData.item_total_price,
      tax_total_price: event.target.value,
      shipping_discount: priceData.shipping_discount,
      items: priceData.items,
    });
  };
  const handleDiscount = async (event) => {
    await setpriceData({
      id: priceData.id,
      reference_id: priceData.reference_id,
      frequency: priceData.frequency,
      nb_frequency: priceData.nb_frequency,
      id_flaterate: priceData.id_flaterate,
      description: priceData.description,
      custom_id: priceData.custom_id,
      soft_descriptor: priceData.soft_descriptor,
      currency_code: priceData.currency_code,
      item_total_price: priceData.item_total_price,
      tax_total_price: priceData.tax_total_price,
      shipping_discount: event.target.value,
      items: priceData.items,
    });
  };

  const createNewFlateRate = async (e) => {
    e.preventDefault();
    const updateprofile =
      process.env.REACT_APP_URL + '/api/price//updatepricing';
    try {
      console.log(priceData);
      const response = await fetch(updateprofile, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token,
        },
        body: JSON.stringify(priceData),
      });
      if (response.ok) {
        window.location.assign('/pricing');
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="popup">
      <div
        className="popup_inner"
        style={{
          zIndex: 'inherit',
        }}
      >
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row row-popup">
          <div className="col-12">
            <form>
              <div className="form-group">
                <label>Reference ID</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => handleRefIDAndCustomID(e)}
                  value={priceData.reference_id}
                />
              </div>
              <div className="form-group">
                <select
                  onChange={(e) => handleFrequency(e)}
                  className="browser-default custom-select"
                >
                  {Addfrequency.map((Addfrequencyres, key) => (
                    <option key={key} value={key}>
                      {Addfrequencyres}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Number of {priceData.frequency}</label>
                <input
                  type="number"
                  className="form-control"
                  value={priceData.nb_frequency}
                  onChange={(e) => handleNBFrequency(e)}
                />
              </div>

              <div className="form-group">
                <label> Soft Description </label>
                <input
                  type="text"
                  className="form-control"
                  value={priceData.soft_descriptor}
                  onChange={(e) => handleSoftDescriptor(e)}
                />
              </div>
              <div className="form-group">
                <label> Description </label>
                <input
                  type="text"
                  className="form-control"
                  value={priceData.description}
                  onChange={(e) => handleDescription(e)}
                />
              </div>
              <div className="form-group">
                <label>Forfait</label>
                <select
                  onChange={(e) => handleIDFlateRate(e)}
                  className="browser-default custom-select"
                >
                  {AddFlatRate.map((AddFlatRateres, key) => (
                    <option key={key} value={AddFlatRateres.id}>
                      {AddFlatRateres.nameforfait}
                      {' = '}card
                      {': '}
                      {AddFlatRateres.numbercvf}
                      {' / '}url {': '}
                      {AddFlatRateres.numberurl}
                    </option>
                  ))}
                </select>
              </div>
              <AddTaskForm addTask={addTask} />
              <label> List Items to ADD:</label>
              {tasksItems.map((task, index) => (
                <div
                  key={index}
                  style={{
                    borderColor: 'black',
                    borderStyle: 'inset',
                    margin: '5px',
                  }}
                >
                  <div className={classes.root}>
                    <Grid container spacing={3}>
                      <Grid item xs={9}>
                        <Paper
                          className={classes.paper}
                          style={{
                            boxShadow: '0px',
                            color: 'black',
                            boxShadow:
                              ' 0px 0px 0px 0px rgb(255 255 255), 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
                          }}
                        >
                          <p>
                            Description: {task.description} {' | '} name:{' '}
                            {task.name}
                          </p>
                          <p>
                            sku: {task.sku}
                            {' | '}
                            quantity: {task.quantity}
                            {' | '}unity: {task.unit_amount.value}
                            {/* {' | '}total_items: {task.value} */}
                            {task.currency_code}
                          </p>
                        </Paper>
                      </Grid>
                      <Grid item xs={3}>
                        <Paper
                          className={classes.paper}
                          style={{
                            boxShadow:
                              ' 0px 0px 0px 0px rgb(255 255 255), 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
                          }}
                        >
                          <button
                            style={{
                              borderRadius: '10px',
                              padding: '4px',
                              margin: '3px',

                              backgroundColor: '#fd0707',
                            }}
                            onClick={() => removeTask(index)}
                          >
                            <i>
                              <TrashIcon />
                            </i>
                          </button>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ))}

              <div>TOTAL GLOBAL :{priceData.item_total_price}</div>
              <div className="form-group">
                <label>Discount </label>
                <input
                  type="number"
                  className="form-control"
                  value={priceData.shipping_discount}
                  onChange={(e) => handleDiscount(e)}
                />
              </div>
              <div className="form-group">
                <label>Taxs</label>
                <input
                  type="number"
                  className="form-control"
                  value={priceData.tax_total_price}
                  onChange={(e) => handleTax(e)}
                />
              </div>

              <button
                className="btn btn-primary"
                onClick={(e) => createNewFlateRate(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupUpdateForfait;
