import React, { Component } from 'react';
import imgAvatar from './../../../../../img/img_avatar.png';
export class DisplayPhone extends Component {
  // constructor(props) {
  //   super(props);
  // }
  listPhone() {
    let workPhone = this.props.workPhone;
    return (
      <ul>
        {workPhone.map((val, index) => {
          return <p key={index}>{val}</p>;
        })}
      </ul>
    );
  }
  listFax() {
    let workFax = this.props.workFax;
    return (
      <ul>
        {workFax.map((val, index) => {
          return <p key={index}>{val}</p>;
        })}
      </ul>
    );
  }
  listEmail() {
    let workEmail = this.props.workEmail;
    return (
      <ul>
        {workEmail.map((val, index) => {
          return <p key={index}>{val}</p>;
        })}
      </ul>
    );
  }
  render() {
    const renderAvatar = () => {
      if (this.props.file === null) {
        return <img className="card-img-top" src={imgAvatar} alt="form" />;
      } else {
        return (
          <img src={this.props.file} className="card-img-top" alt="form" />
        );
      }
    };
    return (
      <div className="smartphone">
        <div className="content text-center contentphone">
          {renderAvatar()}

          <div className="identity" onChange={this.props.handleChange}>
            <div>
              {this.props.prefix} {this.props.firstName} {this.props.lastName}
            </div>
            <div>
              {this.props.organization} {this.props.title}
            </div>
          </div>
          <div className="text-left cadre-phone">
            <div>Phone</div>
            <div>{this.listPhone()}</div>
            <div>Email</div>
            <div>{this.listEmail()}</div>
            <div>Fax</div>
            <div>{this.listFax()}</div>
          </div>
          <div className="text-left address-company cadre-phone">
            <div>Address</div>
            <div className="space-phone">
              {this.props.workaddrlabel} {this.props.workaddrstreet}{' '}
              {this.props.workaddrcity} {this.props.workaddrzipcode}{' '}
              {this.props.workaddrcountry}
            </div>
          </div>
          <div className="text-left cadre-phone">
            <div>Website</div>
            <div className="space-phone">{this.props.workurl}</div>
          </div>
          <div className="text-left cadre-phone">
            <div>Social Network</div>
            <div className="space-phone">
              <p>
                {this.props.facebook !== '' && 'Facebook: '}
                {this.props.facebook}
              </p>
              <p>
                {this.props.twitter !== '' && 'Twitter: '}
                {this.props.twitter}
              </p>
              <p>
                {this.props.instagram !== '' && 'Instagram: '}
                {this.props.instagram}
              </p>
              <p>
                {this.props.pinterest !== '' && 'Pinterest: '}
                {this.props.pinterest}
              </p>
              <p>
                {this.props.linkedin !== '' && 'Linkedin: '}
                {this.props.linkedin}
              </p>
              <p>
                {this.props.youtube !== '' && 'Youtube: '}
                {this.props.youtube}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
