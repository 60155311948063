import React, { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from './../../Page';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CustomerListView = () => {
  const classes = useStyles();
  // const [customers] = useState(data);
  const [customers, setCustomers] = useState([]);
  const [nbTotalUser, setNbTotalUser] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [beginPage, setBeginPage] = useState(0);
  const [endPage, setEndPage] = useState(10);

  const handleLimitChange = (event) => {
    let limitVal = event.target.value;
    setLimit(limitVal);
    if (page === 0) {
      setBeginPage(0);
      setEndPage(limitVal);
    } else {
      setBeginPage(page * limitVal);
      setEndPage(limitVal * page + limitVal);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (newPage === 0) {
      setBeginPage(0);
      setEndPage(limit);
    } else {
      setBeginPage(newPage * limit);
      setEndPage(limit * newPage + limit);
    }
  };
  const Info = async () => {
    const token = localStorage.getItem('sctu');
    const url = process.env.REACT_APP_URL + '/api/clients/infocustomers';
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();

      setCustomers(json);
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  const NbUser = async () => {
    const token = localStorage.getItem('sctu');
    const url = process.env.REACT_APP_URL + '/api/clients//nbuser';
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();
      setNbTotalUser(parseInt(json.count));
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  useEffect(async () => {
    async function fetchMyAPI() {
      if (nbTotalUser == 0) {
        let nbuser = await NbUser();
      }
      let response = await Info();
    }
    fetchMyAPI();
  }, []);

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Toolbar
          customers={customers}
          limit={limit}
          page={page}
          beginPage={beginPage}
          endPage={endPage}
          handleLimitChange={handleLimitChange}
          handlePageChange={handlePageChange}
        />
      </Container>
    </Page>
  );
};

export default CustomerListView;
