import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from './../../Page';
import Toolbar from './Toolbar';
import ProductCard from './ProductCard';
import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: '100%',
  },
}));

const ProductList = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [nbTotalUser, setNbTotalUser] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [beginPage, setBeginPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const [maxPage, setMaxPage] = useState(0);

  const handleLimitChange = (event) => {
    let limitVal = event.target.value;
    setLimit(limitVal);
    setMaxPage(Math.ceil(nbTotalUser / limitVal));
    if (page === 0) {
      setBeginPage(0);
      setEndPage(limitVal);
    } else {
      setBeginPage(page * limitVal);
      setEndPage(limitVal * page + limitVal);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (newPage === 0) {
      setBeginPage(0);
      setEndPage(limit);
    } else {
      setBeginPage(newPage * limit);
      setEndPage(limit * newPage + limit);
    }
  };
  const NbUser = async () => {
    const token = localStorage.getItem('sctu');
    const url = process.env.REACT_APP_URL + '/api/clients//nbuser';
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();

      setNbTotalUser(parseInt(json.count));
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };

  const Info = async () => {
    const token = localStorage.getItem('sctu');
    const url = process.env.REACT_APP_URL + '/api/clients/infocustomers';
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();
      setCustomers(json);
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };

  // const InfoMore100 = async () => {
  //   const token = localStorage.getItem('sctu');
  //   ///:pageNumber/:limitUser
  //   const url =
  //     process.env.REACT_APP_URL +
  //     '/api/clients/infocustomerspage/' +
  //     page +
  //     '/' +
  //     limit;
  //   const response = await fetch(url, {
  //     headers: { Authorization: 'bearer ' + token },
  //   });
  //   if (response.ok) {
  //     const json = await response.json();
  //     setCustomers(json);
  //   } else {
  //     console.log('token need to be refrech');
  //     // window.location.assign('/signin');
  //   }
  // };
  useEffect(async () => {
    async function fetchMyAPI() {
      if (nbTotalUser == 0) {
        let nbuser = await NbUser();
      }
      let response = await Info();
    }
    fetchMyAPI();
  }, []);

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Toolbar
          customers={customers}
          limit={limit}
          page={page}
          beginPage={beginPage}
          endPage={endPage}
          maxPage={maxPage}
          nbTotalUser={nbTotalUser}
          handleLimitChange={handleLimitChange}
          handlePageChange={handlePageChange}
        />
      </Container>
    </Page>
  );
};

export default ProductList;
