import React, { useEffect, useRef, useState, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.green[900],
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
  valueAdmin: {
    color: colors.red[900],
  },
}));

const TotalCustomers = (props, { className, ...rest }) => {
  const classes = useStyles();
  const [info, setInfo] = useState({
    nbusers: '',
    nbbasic: '',
    nbfreelance: '',
    nbcompany: '',
    nbadmin: '',
  });

  useEffect(() => {
    const test = props.info;
    if (test != undefined) {
      setInfo(test.info);
    }
  });
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL CUSTOMERS
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {info.nbusers}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <Typography className={classes.differenceValue} variant="body2">
            Free Version :
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {info.nbbasic}
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="center">
          {/* <ArrowUpwardIcon className={classes.differenceIcon} /> */}
          <Typography className={classes.differenceValue} variant="body2">
            Freelance :
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {info.nbfreelance}
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="center">
          {/* <ArrowUpwardIcon className={classes.differenceIcon} /> */}
          <Typography className={classes.differenceValue} variant="body2">
            Company:
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {info.nbcompany}
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="center">
          {/* <ArrowUpwardIcon className={classes.differenceIcon} /> */}
          <Typography className={classes.valueAdmin} variant="body2">
            Admin Keynegdo:
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {info.nbadmin}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

TotalCustomers.propTypes = {
  className: PropTypes.string,
};

export default TotalCustomers;
