import React, { useRef, useEffect } from 'react';
import { generateQRUpdate } from './ModelQRcode.js';

const UpdateQrcode = (props) => {
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  let path = process.env.REACT_APP_URL2QR + '/vcard/testurlqrcode';
  // let logo = null; //await getLogo(qrcode);
  useEffect(() => {
    generateQRUpdate(
      path,
      canvasRef.current,
      '',
      {
        color_dark: '#000000',
        color_light: '#FFFFFF',
      },
      null,
      props.data
    );
  }, [props.data]);

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div id="container" className="row justify-content-center ">
            <canvas ref={(el) => (canvasRef.current = el)}></canvas>
          </div>
        </div>
      </div>
      <canvas ref={inputRef} />
    </div>
  );
};
export default UpdateQrcode;
