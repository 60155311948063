import React, { useRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
import UpdateQrcode from './updateQrcode';
import './popupEditLogo.css';

const PopupUpdateLogo = (props) => {
  const [data, setData] = useState({
    name: '',
    id_logo: 0,
    sizeronwidth: 0,
    sizeronheight: 0,
    sizeQrcode: 0,
    x_coor: 0,
    y_coor: 0,
    x_coor2: 0,
    y_coor2: 0,
    image: '',
    nb_qr: 0,
    raw: null,
    status: '',
  });
  const token = localStorage.getItem('sctu');

  const initData = async (data) => {
    let url = process.env.REACT_APP_URL + '/api/upload/getlogo/' + data.image;
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });

    if (response.ok) {
      const json2 = await response;
      return json2;
    } else {
      console.log('not logo ');
    }
  };

  const handelNameCompany = (event) => {
    event.preventDefault();
    setData({
      name: event.currentTarget.value,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelSizeronwidth = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: parseInt(event.currentTarget.value),
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };

  const updateStatus = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: event.currentTarget.value,
    });
  };
  const updateNbQR = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: parseInt(event.currentTarget.value),
      raw: data.raw,
      status: data.status,
    });
  };
  const handelSizeronheight = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: parseInt(event.currentTarget.value),
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      status: data.status,
    });
  };
  const handelsizeQrcode = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: parseInt(event.currentTarget.value),
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };

  const handelX_coor = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: parseInt(event.currentTarget.value),
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelX_coor2 = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: parseInt(event.currentTarget.value),
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelY_coor = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: parseInt(event.currentTarget.value),
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelY_coor2 = (event) => {
    event.preventDefault();
    setData({
      name: props.datalogo.name,
      id_logo: data.id_logo,
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: parseInt(event.currentTarget.value),
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };

  const SendData = async (event) => {
    event.preventDefault();
    let url = process.env.REACT_APP_URL + '/api/upload/updateimage';

    const response = await fetch(url, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const json = await response.json();
      window.location.assign('/settings');
    } else {
      console.log('upload fail');
    }
  };
  useEffect(() => {
    async function fetchMyAPI() {
      const json2 = await initData(props.datalogo);
      setData({
        name: props.datalogo.name,
        id_logo: parseInt(props.datalogo.id),
        sizeronwidth: parseInt(props.datalogo.sizewidth),
        sizeronheight: parseInt(props.datalogo.sizeheight),
        sizeQrcode: parseInt(props.datalogo.sizeqr),
        x_coor: parseInt(props.datalogo.x_1),
        y_coor: parseInt(props.datalogo.y_1),
        x_coor2: parseInt(props.datalogo.x_2),
        y_coor2: parseInt(props.datalogo.y_2),
        image: props.datalogo.image,
        nb_qr: parseInt(props.datalogo.nb_qr),
        raw: json2.url,
        status: props.datalogo.status,
      });
    }
    fetchMyAPI();
  }, []);
  return (
    <div className="popup">
      <div className="popup_inner">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row row-popup">
          <div className="col-3">
            <div className="form-group">
              <label>sizeronwidth</label>
              <input
                type="number"
                onChange={(e) => handelSizeronwidth(e)}
                value={data.sizeronwidth}
                className="inputnumber"
              />
            </div>
            {data.nb_qr == 2 ? (
              <div className="form-group">
                <label>sizeronheight</label>
                <input
                  type="number"
                  className="inputnumber"
                  onChange={(e) => handelSizeronheight(e)}
                  value={data.sizeronheight}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div className="form-group">
              <label>sizeQrcode</label>
              <input
                className="inputnumber"
                type="number"
                onChange={(e) => handelsizeQrcode(e)}
                value={data.sizeQrcode}
              />
            </div>
            <div className="form-group">
              <label>Number QRCODE</label>
              <select value={data.nb_qr} onChange={(e) => updateNbQR(e)}>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>x QRCODE1</label>
              <input
                type="number"
                className="inputnumber"
                onChange={(e) => handelX_coor(e)}
                value={data.x_coor}
              />
            </div>
            <div className="form-group">
              <label>y QRCODE1</label>
              <input
                type="number"
                className="inputnumber"
                onChange={(e) => handelY_coor(e)}
                value={data.y_coor}
              />
            </div>
          </div>
          {data.nb_qr == 2 ? (
            <div className="col-3">
              <div className="form-group">
                <label>x QRCODE2</label>
                <input
                  type="number"
                  className="inputnumber"
                  onChange={(e) => handelX_coor2(e)}
                  value={data.x_coor2}
                />
              </div>
              <div className="form-group">
                <label>y QRCODE2</label>
                <input
                  type="number"
                  className="inputnumber"
                  onChange={(e) => handelY_coor2(e)}
                  value={data.y_coor2}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="col-3">
            <div className="form-group">
              <label>Name logo Company</label>
              <input
                type="text"
                onChange={(e) => handelNameCompany(e)}
                value={data.name}
              />
            </div>
            <div className="form-group">
              <label>Status: </label>
              <select value={data.status} onChange={(e) => updateStatus(e)}>
                <option value="public">public</option>
                <option value="company">company</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <button type="submit" className="btn btn-success " onClick={SendData}>
            add Image
          </button>
        </div>
        <br></br>
        <UpdateQrcode data={data} />
      </div>
    </div>
  );
};

export default PopupUpdateLogo;
