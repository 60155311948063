import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PopupEdit from './popupEdit';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));
function getIdUser() {
  let url_string = window.location.href;
  var st = new URL(url_string);
  var id = st.searchParams.get('id');
  return id;
}

async function deleteURrl(idurl) {
  const parameters = '?idurl=' + idurl;
  const iduser = getIdUser();
  let token = localStorage.getItem('sctu');
  const urldelete =
    process.env.REACT_APP_URL + '/api/urldynamic/deleteurl' + parameters;
  try {
    const response = await fetch(urldelete, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token,
      },
      body: JSON.stringify({ test: 'test' }),
    });
    if (response.ok) {
      window.location.assign('/urls?id=' + iduser);
    } else {
      ////console.log(response);
    }
  } catch (error) {
    ////console.log(error);
  }
}

const Results = ({ className, urls, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [beginPage, setBeginPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  let toggleEdit = [];
  urls.forEach((el) => {
    toggleEdit.push(false);
  });
  const [Edit, setEdit] = useState(toggleEdit);
  const handleEdit = (event, i) => {
    event.preventDefault();
    const newEdit = [...Edit];
    newEdit[i] = !newEdit[i];
    setEdit(newEdit);
  };

  const handleLimitChange = (event) => {
    let limitVal = event.target.value;
    setLimit(limitVal);
    if (page === 0) {
      setBeginPage(0);
      setEndPage(limitVal);
    } else {
      setBeginPage(page * limitVal);
      setEndPage(limitVal * page + limitVal);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (newPage === 0) {
      setBeginPage(0);
      setEndPage(limit);
    } else {
      setBeginPage(newPage * limit);
      setEndPage(limit * newPage + limit);
    }
  };

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;
    if (event.target.checked) {
      newSelectedCustomerIds = urls.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === urls.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < urls.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Title</TableCell>
                <TableCell>url</TableCell>
                <TableCell>Link in QR</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell>Qrcode</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {urls.slice(beginPage, endPage).map((url, i) => (
                <TableRow
                  hover
                  key={url.id}
                  selected={selectedCustomerIds.indexOf(url.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(url.id) !== -1}
                      onChange={(event) => handleSelectOne(event, url.id)}
                      value="true"
                    />
                  </TableCell>

                  <TableCell>{url.title}</TableCell>
                  <TableCell>{url.url}</TableCell>
                  <TableCell>{url.getlink}</TableCell>
                  <TableCell>
                    <button
                      className="btn btn-warning  btn-circle"
                      onClick={(e) => handleEdit(e, i)}
                    >
                      <EditIcon />
                    </button>
                    <button
                      className="btn   btn-circle"
                      onClick={() => deleteURrl(url.id)}
                    >
                      <DeleteIcon />
                    </button>
                    {Edit[i] ? (
                      <div>
                        <PopupEdit
                          closePopup={(e) => handleEdit(e, i)}
                          urlsinfo={url}
                          iduser={getIdUser()}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </TableCell>
                  <TableCell>{/* Qrcode  */}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={urls.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  urls: PropTypes.array.isRequired,
};

export default Results;
