import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
// import Menu from './components/Menu/Menu';
import LoginView from './components/Admin/auth/LoginView';
import DashboardPage from './components/Admin/DashboardLayout/Dashboard';
import CustomerPage from './components/Admin/DashboardLayout/Customer';
import ProductsPage from './components/Admin/DashboardLayout/Products';
import UrlsListPage from './components/Admin/DashboardLayout/UrlsList';
import CardsListPage from './components/Admin/DashboardLayout/CardsList';
import SettingsPage from './components/Admin/DashboardLayout/Settings';
import PackageList from './components/Admin/DashboardLayout/PackageList';
import PrincingList from './components/Admin/DashboardLayout/PrincingList';
import './lang/i18n';
import Test from './components/test';
function App() {
  return (
    <div className="App">
      <Suspense fallback={<div></div>}>
        <I18nextProvider>
          <BrowserRouter>
            {/* <Menu /> */}
            <Switch>
              <Route exact path="/" component={LoginView} />
              <Route exact path="/package" component={PackageList} />
              <Route exact path="/pricing" component={PrincingList} />
              <Route exact path="/dashboard" component={DashboardPage} />
              <Route exact path="/customers" component={CustomerPage} />
              <Route exact path="/products" component={ProductsPage} />
              <Route exact path="/urls" component={UrlsListPage} />
              <Route exact path="/cards" component={CardsListPage} />
              <Route exact path="/settings" component={SettingsPage} />
              <Route exact path="/test" component={Test} />
            </Switch>
          </BrowserRouter>
        </I18nextProvider>
      </Suspense>
    </div>
  );
}

export default App;
