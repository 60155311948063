import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import Results from './Results';
import { Search as SearchIcon } from 'react-feather';
import PopupEditForfait from './popupEditforfait';
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  allValuesflaterate,
  allValuesPrice,
  ...rest
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [toggleEditForfait, setToggleEditForfait] = useState(false);
  const handleChange = (event) => {
    let searchValue = event.target.value;
    setSearch(searchValue);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setToggleEditForfait(!toggleEditForfait);
  };
  // Filter the table data
  let customeFilter = allValuesPrice.prices,
    searchString = search.trim().toLowerCase();

  if (searchString.length > 0) {
    // We are searching. Filter the results.
    customeFilter = customeFilter.filter((e) =>
      e.reference_id.toLowerCase().match(searchString)
    );
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end" style={{ margin: '5px' }}>
        {/* <Button className={classes.importButton}>Import</Button> */}
        {/* <Button className={classes.exportButton}>Export</Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => handleEdit(e)}
        >
          Add Price
        </Button>
      </Box>
      {toggleEditForfait ? (
        <div>
          <PopupEditForfait
            closePopup={(e) => handleEdit(e)}
            flaterate={allValuesflaterate.flaterate}
          />
        </div>
      ) : (
        <div></div>
      )}
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={300}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange(e)}
                placeholder="Search by Titles"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box mt={3}>
        <Results
          prices={customeFilter}
          flaterate={allValuesflaterate.flaterate}
        />
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
