import React, { useRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';

const SelectShape = (props) => {
  const token = localStorage.getItem('sctu');
  const [shapeData, setShapeData] = useState([]);
  const getSelectionShape = async () => {
    const url = process.env.REACT_APP_URL + '/api/design/getallShapes';
    ////console.log(url);
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();
      setShapeData(json.shapes);
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  useEffect(async () => {
    async function fetchMyAPI() {
      let response = await getSelectionShape();
    }
    fetchMyAPI();
  }, []);
  const Items = shapeData.map((team) => (
    <option key={team.id} value={team.name}>
      {team.name}
    </option>
  ));

  return (
    <div>
      <select value={props.shape} onChange={(e) => props.update(e)}>
        {Items}
      </select>
    </div>
  );
};

export default SelectShape;
