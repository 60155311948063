import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import Results from './Results';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, logos, ...rest }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const handleChange = (event) => {
    let searchValue = event.target.value;
    setSearch(searchValue);
  };
  // Filter the table data
  let customeFilter = logos.infologos,
    searchString = search.trim().toLowerCase();

  if (searchString.length > 0) {
    // We are searching. Filter the results.
    customeFilter = customeFilter.filter((e) =>
      e.name.toLowerCase().match(searchString)
    );
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={300}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange(e)}
                placeholder="Search customer email"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box mt={3}>
        {customeFilter !== undefined ? (
          <Results logos={customeFilter} />
        ) : (
          <div></div>
        )}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
