import React, { useRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
import {
  generateQR,
  generateQRUpdate,
} from './../components/Admin/DashboardLayout/SettingsView/ModelQRcode';

const Test = () => {
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  let logo = null; //await getLogo(qrcode);
  let path = process.env.REACT_APP_URL2QR + '/vcard/testurlqrcode';
  const [nameCompany, setNameCompany] = useState('test');
  const [data, setData] = useState({
    name: '',
    id_logo: 0,
    sizeronwidth: 0,
    sizeronheight: 0,
    sizeQrcode: 0,
    x_coor: 0,
    y_coor: 0,
    x_coor2: 0,
    y_coor2: 0,
    image: 'photo-camera.svg',
    nb_qr: 0,
    raw: null,
    status: '',
  });
  const token = localStorage.getItem('sctu');

  const initData = async (data) => {
    let url = process.env.REACT_APP_URL + '/api/upload/getlogo/' + data.image;
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });

    if (response.ok) {
      const json2 = await response;
      return json2;
    } else {
      console.log('not logo ');
    }
  };
  const Info = async () => {
    const url = process.env.REACT_APP_URL + '/api/design/getshape/' + 'test';
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      setData(json);
      return json;
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };

  useEffect(() => {
    async function fetchMyAPI() {
      const json = await Info();
      const json2 = await initData(json.shapes);

      await setData({
        name: json.shapes.name,
        id_logo: parseInt(json.shapes.id),
        sizeronwidth: parseInt(json.shapes.sizewidth),
        sizeronheight: parseInt(json.shapes.sizeheight),
        sizeQrcode: parseInt(json.shapes.sizeqr),
        x_coor: parseInt(json.shapes.x_1),
        y_coor: parseInt(json.shapes.y_1),
        x_coor2: parseInt(json.shapes.x_2),
        y_coor2: parseInt(json.shapes.y_2),
        image: json.shapes.image,
        nb_qr: parseInt(json.shapes.nb_qr),
        raw: json2.url,
        status: json.shapes.status,
      });
      await generateQRUpdate(
        path,
        canvasRef.current,
        '',
        {
          color_dark: '#000000',
          color_light: '#FFFFFF',
        },
        null,
        {
          name: json.shapes.name,
          id_logo: parseInt(json.shapes.id),
          sizeronwidth: parseInt(json.shapes.sizewidth),
          sizeronheight: parseInt(json.shapes.sizeheight),
          sizeQrcode: parseInt(json.shapes.sizeqr),
          x_coor: parseInt(json.shapes.x_1),
          y_coor: parseInt(json.shapes.y_1),
          x_coor2: parseInt(json.shapes.x_2),
          y_coor2: parseInt(json.shapes.y_2),
          image: json.shapes.image,
          nb_qr: parseInt(json.shapes.nb_qr),
          raw: json2.url,
          status: json.shapes.status,
        }
      );
    }
    fetchMyAPI();
  }, []);
  return (
    <div className="popup">
      <div className="popup_inner">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div id="container" className="row justify-content-center ">
              <canvas ref={(el) => (canvasRef.current = el)}></canvas>
            </div>
          </div>
        </div>
        <canvas ref={inputRef} />
      </div>
    </div>
  );
};

export default Test;
