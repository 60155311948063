import React, { useState, useEffect } from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import PopupEditLogo from './popupEditLogo';
import PopupUpdateLogo from './popupUpdateLogo';
import { Box } from '@material-ui/core';
import Toolbar from './Toolbar';
import Page from './../../Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
}));

const SettingsView = () => {
  const classes = useStyles();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [logos, setlogos] = useState([]);
  const handleEdit = (event) => {
    event.preventDefault();
    setToggleEdit(!toggleEdit);
  };
  const handleUpdate = (event) => {
    event.preventDefault();
    setToggleUpdate(!toggleUpdate);
  };
  const Info = async () => {
    const token = localStorage.getItem('sctu');
    const url = process.env.REACT_APP_URL + '/api/upload/all-logos';
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + token },
    });
    if (response.ok) {
      const json = await response.json();
      setlogos(json);
    } else {
      console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  useEffect(async () => {
    async function fetchMyAPI() {
      let response = await Info();
    }
    fetchMyAPI();
  }, []);
  return (
    <Page className={classes.root} title="Setting">
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-end" style={{ margin: '5px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleEdit(e)}
          >
            Add new Logo
          </Button>
        </Box>
        {toggleEdit ? (
          <div>
            <PopupEditLogo closePopup={(e) => handleEdit(e)} />
          </div>
        ) : (
          <div></div>
        )}

        <Toolbar logos={logos} />
      </Container>
    </Page>
  );
};

export default SettingsView;
