import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import getInitials from './getInitials';
import EditIcon from '@material-ui/icons/Edit';
import PopupEdit from './popupEdit';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  customers,
  limit,
  page,
  beginPage,
  endPage,
  handleLimitChange,
  handlePageChange,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  let toggleEdit = [];
  customers.forEach((el) => {
    toggleEdit.push(false);
  });
  const [Edit, setEdit] = useState(toggleEdit);
  const handleEdit = (event, i) => {
    event.preventDefault();
    const newEdit = [...Edit];
    newEdit[i] = !newEdit[i];
    setEdit(newEdit);
  };
  // const handleSelectAll = (event) => {
  //   let newSelectedCustomerIds;
  //   if (event.target.checked) {
  //     newSelectedCustomerIds = customers.map((customer) => customer.id);
  //   } else {
  //     newSelectedCustomerIds = [];
  //   }
  //   setSelectedCustomerIds(newSelectedCustomerIds);
  // };

  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedCustomerIds.indexOf(id);
  //   let newSelectedCustomerIds = [];

  //   if (selectedIndex === -1) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds,
  //       id
  //     );
  //   } else if (selectedIndex === 0) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds.slice(1)
  //     );
  //   } else if (selectedIndex === selectedCustomerIds.length - 1) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds.slice(0, -1)
  //     );
  //   } else if (selectedIndex > 0) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds.slice(0, selectedIndex),
  //       selectedCustomerIds.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelectedCustomerIds(newSelectedCustomerIds);
  // };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}

                <TableCell>Email</TableCell>
                <TableCell> Edit Profil</TableCell>
                <TableCell>Creation Date</TableCell>
                <TableCell>End Contract</TableCell>
                <TableCell>Forfait</TableCell>
                <TableCell>Last Payment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.slice(beginPage, endPage).map((customer, i) => (
                <TableRow
                  hover
                  key={customer.id}
                  selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.id)}
                      value="true"
                    />
                  </TableCell> */}
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={customer.avatarUrl}
                      >
                        {getInitials(customer.email)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {customer.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <button
                      className="btn btn-warning  btn-circle"
                      onClick={(e) => handleEdit(e, i)}
                    >
                      <EditIcon />
                    </button>
                    {Edit[i] ? (
                      <div>
                        <PopupEdit
                          closePopup={(e) => handleEdit(e, i)}
                          infoCustomer={customer}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(customer.creation).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(customer.end_contract).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>{customer.nameforfait}</TableCell>
                  <TableCell>
                    {moment(customer.lastpayment).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default Results;
