import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PopupUpdateForfait from './popupUpdateForfait';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

async function deletePrice(id, custom_id) {
  let token = localStorage.getItem('sctu');
  const urldelete = process.env.REACT_APP_URL + '/api/price/deleteprice';
  try {
    const response = await fetch(urldelete, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token,
      },
      body: JSON.stringify({ id: id, custom_id: custom_id }),
    });
    if (response.ok) {
      window.location.assign('/pricing');
    } else {
      ////console.log(response);
    }
  } catch (error) {
    ////console.log(error);
  }
}

const Results = ({ className, prices, flaterate, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [beginPage, setBeginPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  let toggleEdit = [];
  prices.forEach((el) => {
    toggleEdit.push(false);
  });

  const [Edit, setEdit] = useState(toggleEdit);
  const handleEdit = (event, i) => {
    event.preventDefault();
    const newEdit = [...Edit];
    newEdit[i] = !newEdit[i];
    setEdit(newEdit);
  };

  const handleLimitChange = (event) => {
    let limitVal = event.target.value;
    setLimit(limitVal);
    if (page === 0) {
      setBeginPage(0);
      setEndPage(limitVal);
    } else {
      setBeginPage(page * limitVal);
      setEndPage(limitVal * page + limitVal);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (newPage === 0) {
      setBeginPage(0);
      setEndPage(limit);
    } else {
      setBeginPage(newPage * limit);
      setEndPage(limit * newPage + limit);
    }
  };

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;
    if (event.target.checked) {
      newSelectedCustomerIds = prices.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedCustomerIds.indexOf(id);
  //   let newSelectedCustomerIds = [];

  //   if (selectedIndex === -1) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds,
  //       id
  //     );
  //   } else if (selectedIndex === 0) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds.slice(1)
  //     );
  //   } else if (selectedIndex === selectedCustomerIds.length - 1) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds.slice(0, -1)
  //     );
  //   } else if (selectedIndex > 0) {
  //     newSelectedCustomerIds = newSelectedCustomerIds.concat(
  //       selectedCustomerIds.slice(0, selectedIndex),
  //       selectedCustomerIds.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelectedCustomerIds(newSelectedCustomerIds);
  // };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === prices.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < prices.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                <TableCell>Promo Code</TableCell>
                <TableCell>frequency</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Name Forfait:Card/URL</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prices.slice(beginPage, endPage).map((price, i) => (
                <TableRow
                  hover
                  key={price.id}
                  style={{ overflowY: 'auto' }}
                  selected={selectedCustomerIds.indexOf(price.id) !== -1}
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(price.id) !== -1}
                      onChange={(event) => handleSelectOne(event, price.id)}
                      value="true"
                    />
                  </TableCell> */}

                  <TableCell>{price.reference_id}</TableCell>
                  <TableCell>{price.frequency}</TableCell>
                  <TableCell>
                    {price.item_total_price}
                    {price.currency_code}
                  </TableCell>
                  <TableCell>
                    {price.nameforfait}:{price.numbercvf}/{price.numberurl}
                  </TableCell>

                  <TableCell>
                    <button
                      className="btn btn-warning  btn-circle"
                      onClick={(e) => handleEdit(e, i)}
                    >
                      <EditIcon />
                    </button>
                    <button
                      className="btn   btn-circle"
                      onClick={() => deletePrice(price.id, price.custom_id)}
                    >
                      <DeleteIcon />
                    </button>
                  </TableCell>
                  {Edit[i] ? (
                    <div>
                      <PopupUpdateForfait
                        closePopup={(e) => handleEdit(e, i)}
                        prices={price}
                        flaterate={flaterate}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <TableCell>{/* Qrcode  */}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={prices.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  prices: PropTypes.array.isRequired,
};

export default Results;
