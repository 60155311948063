import React, { useRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
import './popupEditLogo.css';
import { generateQR } from './ModelQRcode.js';

const PopupEditLogo = (props) => {
  const token = localStorage.getItem('sctu');
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  let logo = null; //await getLogo(qrcode);
  let path = process.env.REACT_APP_URL2QR + '/vcard/testurlqrcode';
  const [nameCompany, setNameCompany] = useState('test');

  const [data, setData] = useState({
    sizeronwidth: 192,
    sizeronheight: 192,
    sizeQrcode: 136,
    x_coor: 29,
    y_coor: 29,
    x_coor2: 29,
    y_coor2: 29,
    image: process.env.REACT_APP_DASHBOARD + '/static/qr/rondvideqrcode.png',
    nb_qr: 1,
    raw: null,
    status: 'public',
  });
  const handelNameCompany = (event) => {
    event.preventDefault();
    setNameCompany(event.currentTarget.value);
  };
  const handelSizeronwidth = (event) => {
    event.preventDefault();
    setData({
      sizeronwidth: parseInt(event.currentTarget.value),
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const updateNbQR = (event) => {
    event.preventDefault();
    setData({
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: parseInt(event.currentTarget.value),
      raw: data.raw,
      status: data.status,
    });
  };

  const updateStatus = (event) => {
    event.preventDefault();
    let stat = event.currentTarget.value;
    setData({
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: stat,
    });
  };
  const handelSizeronheight = (event) => {
    event.preventDefault();
    setData({
      sizeronwidth: data.sizeronwidth,
      sizeronheight: parseInt(event.currentTarget.value),
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelsizeQrcode = (event) => {
    event.preventDefault();
    setData({
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: parseInt(event.currentTarget.value),
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };

  const handelX_coor = (event) => {
    event.preventDefault();
    setData({
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: parseInt(event.currentTarget.value),
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelX_coor2 = (event) => {
    event.preventDefault();
    setData({
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: parseInt(event.currentTarget.value),
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelY_coor = (event) => {
    event.preventDefault();
    setData({
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: parseInt(event.currentTarget.value),
      x_coor2: data.x_coor2,
      y_coor2: data.y_coor2,
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelY_coor2 = (event) => {
    event.preventDefault();
    setData({
      sizeronwidth: data.sizeronwidth,
      sizeronheight: data.sizeronheight,
      sizeQrcode: data.sizeQrcode,
      x_coor: data.x_coor,
      y_coor: data.y_coor,
      x_coor2: data.x_coor2,
      y_coor2: parseInt(event.currentTarget.value),
      image: data.image,
      nb_qr: data.nb_qr,
      raw: data.raw,
      status: data.status,
    });
  };
  const handelChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (event.target.files.length) {
      const img = new Image();
      img.onload = function () {
        setData({
          sizeronwidth: this.width,
          sizeronheight: this.height,
          sizeQrcode: data.sizeQrcode,
          x_coor: data.x_coor,
          y_coor: data.y_coor,
          x_coor2: data.x_coor2,
          y_coor2: data.y_coor2,
          image: URL.createObjectURL(file),
          nb_qr: data.nb_qr,
          raw: file,
          status: data.status,
        });
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const resizeImage = async () => {
    const image = new Image();
    image.src = data.raw;
    let can = inputRef.current;
    let context = can.getContext('2d');
    let widthImg = data.sizeronwidth;
    let heightImg = data.sizeronheight;
    // if (widthImg >300) {
    //   widthImg = 300;
    // }
    // if (heightImg > 300) {
    //   heightImg = 300;
    // }
    can.width = widthImg;
    can.height = heightImg;
    context.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      can.width,
      can.height
    );
    ////console.log(canvas.toDataURL('image/jpg'))
    const convertBase64ToFile = function (image) {
      const byteString = atob(image.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      const newBlob = new Blob([ab], {
        type: 'image/jpeg',
      });
      return newBlob;
    };
    return convertBase64ToFile(can.toDataURL());
  };

  const SendLogo = async (event) => {
    event.preventDefault();
    let url = process.env.REACT_APP_URL + '/api/upload/addimage';
    const UrlBase64 = await resizeImage(event);
    if (data.raw !== null) {
      let input = await new File([UrlBase64], data.raw.name);
      if (input.size !== 0) {
        let dataSend = new FormData();
        await dataSend.append('img', data.raw);
        await dataSend.append('data', JSON.stringify(data));
        await dataSend.append('company', nameCompany);
        const response = await fetch(url, {
          method: 'POST',
          body: dataSend,
          headers: { Authorization: 'bearer ' + token },
        });
        // await //console.log(response);
        if (response.ok) {
          const json = await response.json();
          // return json.photoimport;
          // console.log(json);
          window.location.assign('/settings');
        } else {
          console.log('upload fail');
        }
      }
    } else {
      console.log('not new image');
    }
  };
  useEffect(() => {
    generateQR(
      path,
      canvasRef.current,
      '',
      {
        color_dark: '#000000',
        color_light: '#FFFFFF',
      },
      logo,
      data
    );
  }, [data]);
  return (
    <div className="popup">
      <div className="popup_inner">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row row-popup">
          <div className="col-3">
            <div className="form-group">
              <label>sizeronwidth</label>
              <input
                type="number"
                onChange={(e) => handelSizeronwidth(e)}
                value={data.sizeronwidth}
                className="inputnumber"
              />
            </div>
            {data.nb_qr == 2 ? (
              <div className="form-group">
                <label>sizeronheight</label>
                <input
                  type="number"
                  className="inputnumber"
                  onChange={(e) => handelSizeronheight(e)}
                  value={data.sizeronheight}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div className="form-group">
              <label>sizeQrcode</label>
              <input
                className="inputnumber"
                type="number"
                onChange={(e) => handelsizeQrcode(e)}
                value={data.sizeQrcode}
              />
            </div>
            <div className="form-group">
              <label>Number QRCODE</label>
              <select value={data.nb_qr} onChange={(e) => updateNbQR(e)}>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>x QRCODE1</label>
              <input
                type="number"
                className="inputnumber"
                onChange={(e) => handelX_coor(e)}
                value={data.x_coor}
              />
            </div>
            <div className="form-group">
              <label>y QRCODE1</label>
              <input
                type="number"
                className="inputnumber"
                onChange={(e) => handelY_coor(e)}
                value={data.y_coor}
              />
            </div>
          </div>
          {data.nb_qr == 2 ? (
            <div className="col-3">
              <div className="form-group">
                <label>x QRCODE2</label>
                <input
                  type="number"
                  className="inputnumber"
                  onChange={(e) => handelX_coor2(e)}
                  value={data.x_coor2}
                />
              </div>
              <div className="form-group">
                <label>y QRCODE2</label>
                <input
                  type="number"
                  className="inputnumber"
                  onChange={(e) => handelY_coor2(e)}
                  value={data.y_coor2}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="col-3">
            <div className="form-group">
              <label>Input Logo</label>
              <input type="file" onChange={handelChange} />
            </div>
            <div className="form-group">
              <label>Name logo </label>
              <input
                type="text"
                onChange={(e) => handelNameCompany(e)}
                value={nameCompany}
              />
            </div>
            <div className="form-group">
              <label>Status: </label>
              <select value={data.status} onChange={(e) => updateStatus(e)}>
                <option value="public">public</option>
                <option value="company">company</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <button type="submit" className="btn btn-success " onClick={SendLogo}>
            add Image
          </button>
        </div>
        <br></br>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div id="container" className="row justify-content-center ">
              <canvas ref={(el) => (canvasRef.current = el)}></canvas>
            </div>
          </div>
        </div>
        <canvas ref={inputRef} />
      </div>
    </div>
  );
};

export default PopupEditLogo;
