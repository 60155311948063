import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PopupUpdateLogo from './popupUpdateLogo';
import EditIcon from '@material-ui/icons/Edit';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ className, logos, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [beginPage, setBeginPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const token = localStorage.getItem('sctu');
  let toggleEdit = [];
  logos.forEach((el) => {
    toggleEdit.push(false);
  });
  const [Edit, setEdit] = useState(toggleEdit);
  const handleEdit = (event, i) => {
    // console.log(i);
    event.preventDefault();
    const newEdit = [...Edit];
    newEdit[i] = !newEdit[i];
    setEdit(newEdit);
  };

  const handleLimitChange = (event) => {
    let limitVal = event.target.value;
    setLimit(limitVal);
    if (page === 0) {
      setBeginPage(0);
      setEndPage(limitVal);
    } else {
      setBeginPage(page * limitVal);
      setEndPage(limitVal * page + limitVal);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (newPage === 0) {
      setBeginPage(0);
      setEndPage(limit);
    } else {
      setBeginPage(newPage * limit);
      setEndPage(limit * newPage + limit);
    }
  };
  const handleDeleteLogo = async (event, customer) => {
    let url = process.env.REACT_APP_URL + '/api/design/deletelogo/';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token,
      },
      body: JSON.stringify({
        id: customer.id,
        name: customer.name,
      }),
    });
    if (response.ok) {
      window.location('/settings');
    } else {
      console.log('not logo ');
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name Logo</TableCell>
                <TableCell>update</TableCell>
                <TableCell>delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logos.slice(beginPage, endPage).map((customer, i) => (
                <TableRow
                  hover
                  key={customer.id}
                  selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  <TableCell>{customer.name}</TableCell>

                  <TableCell>
                    <button
                      className="btn btn-warning  btn-circle"
                      onClick={(e) => handleEdit(e, i)}
                    >
                      <EditIcon />
                    </button>
                    {Edit[i] ? (
                      <div>
                        <PopupUpdateLogo
                          closePopup={(e) => handleEdit(e, i)}
                          datalogo={customer}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </TableCell>
                  <TableCell>
                    <button
                      className="btn btn-warning  btn-circle"
                      onClick={(e) => handleDeleteLogo(e, customer)}
                    >
                      Delete
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={logos.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  logos: PropTypes.array.isRequired,
};

export default Results;
