import React, { useRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
const PopupUpdateForfait = (props) => {
  const token = localStorage.getItem('sctu');
  const [flateRateData, setflateRateData] = useState(props.packages);

  const handleName = async (event) => {
    await setflateRateData({
      id: flateRateData.id,
      nameforfait: event.target.value,
      numbercvf: flateRateData.numbercvf,
      numberurl: flateRateData.numberurl,
      power_nb: flateRateData.power_nb,
    });
  };
  const handleNbCVF = async (event) => {
    await setflateRateData({
      id: flateRateData.id,
      nameforfait: flateRateData.nameforfait,
      numbercvf: event.target.value,
      numberurl: flateRateData.numberurl,
      power_nb: flateRateData.power_nb,
    });
  };
  const handleNbURL = async (event) => {
    await setflateRateData({
      id: flateRateData.id,
      nameforfait: flateRateData.nameforfait,
      numbercvf: flateRateData.numbercvf,
      numberurl: event.target.value,
      power_nb: flateRateData.power_nb,
    });
  };
  const handleNbPower = async (event) => {
    await setflateRateData({
      id: flateRateData.id,
      nameforfait: flateRateData.nameforfait,
      numbercvf: flateRateData.numbercvf,
      numberurl: flateRateData.numberurl,
      power_nb: event.target.value,
    });
  };

  const updateFlateRate = async () => {
    const updateprofile =
      process.env.REACT_APP_URL + '/api/forfait/updateflaterate';
    try {
      const response = await fetch(updateprofile, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token,
        },
        body: JSON.stringify({
          id: flateRateData.id,
          nameforfait: flateRateData.nameforfait,
          numbercvf: flateRateData.numbercvf,
          numberurl: flateRateData.numberurl,
          power_nb: flateRateData.power_nb,
        }),
      });
      if (response.ok) {
        const json = await response.json();
        window.location.assign('/package');
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="popup">
      <div className="popup_inner">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row row-popup">
          <div className="col-5">
            <form>
              <div className="form-group">
                <label>nameforfait</label>
                <input
                  type="text"
                  onChange={(e) => handleName(e)}
                  value={flateRateData.nameforfait}
                />
              </div>
              <div className="form-group">
                <label>numbercvf</label>
                <input
                  type="text"
                  onChange={(e) => handleNbCVF(e)}
                  value={flateRateData.numbercvf}
                />
              </div>
              <div className="form-group">
                <label>NB URL</label>
                <input
                  type="text"
                  className="form-control"
                  value={flateRateData.numberurl}
                  onChange={(e) => handleNbURL(e)}
                />
              </div>
              <div className="form-group">
                <label> NB Power </label>
                <input
                  type="text"
                  className="form-control"
                  value={flateRateData.power_nb}
                  onChange={(e) => handleNbPower(e)}
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() => updateFlateRate()}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupUpdateForfait;
