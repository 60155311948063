import React, { useRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
import SelectShape from './SelectShape';
import { MasterFormCreate } from './MultStepForm/MasterFormCreate';

const PopupEditNewCard = (props) => {
  const token = localStorage.getItem('sctu');

  return (
    <div className="popup">
      <div className="popup_inner_card">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row row-popup">
          <MasterFormCreate iduser={props.iduser} />
        </div>
      </div>
    </div>
  );
};

export default PopupEditNewCard;
