import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import getInitials from './getInitials';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  customers,
  limit,
  page,
  beginPage,
  endPage,
  handleLimitChange,
  handlePageChange,
  maxPage,
  nbTotalUser,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  const handleURLS = (event, i) => {
    window.location.assign('/urls?id=' + i);
  };

  const handleCards = (event, i) => {
    window.location.assign('/cards?id=' + i);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Cards</TableCell>
                <TableCell>URLs</TableCell>
                <TableCell>FirstName</TableCell>
                <TableCell>LastName</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.slice(beginPage, endPage).map((customer, i) => (
                <TableRow
                  hover
                  key={customer.id}
                  selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={customer.avatarUrl}
                      >
                        {getInitials(customer.email)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {customer.email}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <button
                      className="btn btn-warning  btn-circle"
                      onClick={(e) => handleCards(e, customer.id)}
                    >
                      CARDS
                    </button>
                  </TableCell>
                  <TableCell>
                    <button
                      className="btn btn-warning  btn-circle"
                      onClick={(e) => handleURLS(e, customer.id)}
                    >
                      URLS
                    </button>
                  </TableCell>
                  <TableCell>{customer.firstname}</TableCell>
                  <TableCell>{customer.lastname}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={nbTotalUser}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default Results;
