import React, { useRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
import SelectShape from './SelectShape';
const PopupEdit = (props) => {
  const token = localStorage.getItem('sctu');

  const [urlsData, setUrlsData] = useState({
    color_dark: '#000000',
    color_light: '#FFFFFF',
    getlink: '',
    id: 0,
    logo: null,
    shape: 'circle',
    title: '',
    url: '',
  });
  // console.log(urlsData);
  useEffect(async () => {
    setUrlsData(props.urlsinfo);
  }, []);

  const handleDark = async (event) => {
    event.preventDefault();
    await setUrlsData({
      color_dark: event.currentTarget.value,
      color_light: urlsData.color_light,
      getlink: urlsData.getlink,
      id: urlsData.id,
      logo: null,
      shape: urlsData.shape,
      title: urlsData.title,
      url: urlsData.url,
    });
    //   await buildQrcode();
  };
  const handleLight = async (event) => {
    event.preventDefault();
    await setUrlsData({
      color_dark: urlsData.color_dark,
      color_light: event.currentTarget.value,
      getlink: urlsData.getlink,
      id: urlsData.id,
      logo: null,
      shape: urlsData.shape,
      title: urlsData.title,
      url: urlsData.url,
    });
    // await buildQrcode();
  };
  const handleShape = async (event) => {
    await setUrlsData({
      color_dark: urlsData.color_dark,
      color_light: urlsData.color_light,
      getlink: urlsData.getlink,
      id: urlsData.id,
      logo: null,
      shape: event.currentTarget.value,
      title: urlsData.title,
      url: urlsData.url,
    });
    // await buildQrcode();
  };
  const handleTitle = async (event) => {
    await setUrlsData({
      color_dark: urlsData.color_dark,
      color_light: urlsData.color_light,
      getlink: urlsData.getlink,
      id: urlsData.id,
      logo: null,
      shape: urlsData.shape,
      title: event.currentTarget.value,
      url: urlsData.url,
    });
  };
  const handleURL = async (event) => {
    await setUrlsData({
      color_dark: urlsData.color_dark,
      color_light: urlsData.color_light,
      getlink: urlsData.getlink,
      id: urlsData.id,
      logo: null,
      shape: urlsData.shape,
      title: urlsData.title,
      url: event.currentTarget.value,
    });
  };
  const handleLinkQr = async (event) => {
    await setUrlsData({
      color_dark: urlsData.color_dark,
      color_light: urlsData.color_light,
      getlink: event.currentTarget.value,
      id: urlsData.id,
      logo: null,
      shape: urlsData.shape,
      title: urlsData.title,
      url: urlsData.url,
    });
  };

  const UpdateURL = async () => {
    const updateprofile = process.env.REACT_APP_URL + '/api/urldynamic/seturl';
    try {
      const response = await fetch(updateprofile, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token,
        },
        body: JSON.stringify({
          color_dark: urlsData.color_dark,
          color_light: urlsData.color_light,
          getlink: urlsData.getlink,
          id: urlsData.id,
          logo: null,
          shape: urlsData.shape,
          title: urlsData.title,
          url: urlsData.url,
        }),
      });
      if (response.ok) {
        const json = await response.json();
        window.location.assign('/urls?id=' + props.iduser);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="popup">
      <div className="popup_inner">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row row-popup">
          <div className="col-5">
            <div className="not-form-group">
              <label> ID </label>
              <p className="not-form">{urlsData.id}</p>
            </div>
            <div className="not-form-group">
              <label> Color dark </label>
              <p className="not-form">{urlsData.color_dark}</p>
            </div>
            <div className="not-form-group">
              <label> Color light </label>
              <p className="not-form">{urlsData.color_light}</p>
            </div>
            <div className="not-form-group">
              <label> Shape </label>
              <div className="not-form">{urlsData.shape}</div>
            </div>
            <div className="not-form-group">
              <label> Title </label>
              <div className="not-form">{urlsData.title}</div>
            </div>
            <div className="not-form-group">
              <label> URL USER </label>
              <div className="not-form"> {urlsData.url}</div>
            </div>
            <div className="not-form-group">
              <label> Link in qrcode </label>
              <div className="not-form"> {urlsData.getlink}</div>
            </div>
          </div>
          <div className="col-5">
            <form>
              <div className="form-group">
                <label>Color dark</label>
                <input
                  type="color"
                  onChange={(e) => handleDark(e)}
                  value={urlsData.color_dark}
                />
              </div>
              <div className="form-group">
                <label>Color light</label>
                <input
                  type="color"
                  onChange={(e) => handleLight(e)}
                  value={urlsData.color_light}
                />
              </div>
              <div className="form-group">
                <label>Shape</label>
                <SelectShape shape={urlsData.shape} update={handleShape} />
              </div>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={urlsData.title}
                  onChange={(e) => handleTitle(e)}
                />
              </div>
              <div className="form-group">
                <label> URL USER </label>
                <input
                  type="text"
                  className="form-control"
                  value={urlsData.url}
                  onChange={(e) => handleURL(e)}
                />
              </div>
              <div className="form-group">
                <label> Link Qrcode </label>
                <input
                  type="text"
                  className="form-control"
                  value={urlsData.getlink}
                  onChange={(e) => handleLinkQr(e)}
                />
              </div>

              <button className="btn btn-primary" onClick={() => UpdateURL()}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupEdit;
