import React, { useRef, useEffect, useState } from 'react';
import { Plus as PlusIcon } from 'react-feather';
const AddTaskForm = ({ addTask }) => {
  const [value, setValue] = useState({
    category: 'PHYSICAL_GOODS',
    description: '',
    name: '',
    quantity: 0,
    sku: 'sku',
    tax: { currency_code: 'EUR', value: '0.00' },
    unit_amount: { currency_code: 'EUR', value: 0 },
  });
  // let totalValue = 0;
  const handleDescription = (e) => {
    console.log(value);
    setValue({
      category: value.category,
      description: e.target.value,
      name: value.name,
      quantity: value.quantity,
      sku: value.sku,
      tax: value.tax,
      unit_amount: value.unit_amount,
    });
  };
  const handleName = (e) => {
    setValue({
      category: value.category,
      description: value.description,
      name: e.target.value,
      quantity: value.quantity,
      sku: value.sku,
      tax: value.tax,
      unit_amount: value.unit_amount,
    });
  };
  const handleQuatity = (e) => {
    let quantity = e.target.value;
    setValue({
      category: value.category,
      description: value.description,
      name: value.name,
      quantity: quantity,
      sku: value.sku,
      tax: value.tax,
      unit_amount: value.unit_amount,
    });
    // totalValue = Math.round(quantity * value.unit_amount.value * 100) / 100;
  };
  const handleUnitAmount = (e) => {
    let unit = e.target.value;
    setValue({
      category: value.category,
      description: value.description,
      name: value.name,
      quantity: value.quantity,
      sku: value.sku,
      tax: value.tax,
      unit_amount: { currency_code: 'EUR', value: unit },
    });
    // totalValue = Math.round(value.quantity * unit * 100) / 100;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    value &&
      addTask({
        category: value.category,
        description: value.description,
        name: value.name,
        quantity: value.quantity.toString(),
        sku: value.sku,
        tax: value.tax,
        unit_amount: {
          currency_code: 'EUR',
          value: value.unit_amount.value.toString(),
        },
      });
    // setValue({
    //   category: 'PHYSICAL_GOODS',
    //   description: '',
    //   name: '',
    //   quantity: 0,
    //   sku: 'sku',
    //   tax: { currency_code: 'EUR', value: '0.00' },
    //   unit_amount: { currency_code: 'EUR', value: 0 },
    // });
  };

  return (
    <div
      className="form-group"
      style={{ borderColor: 'gray', borderStyle: 'inset', margin: '5px' }}
    >
      <label> Ajout Items </label>
      <input
        type="text"
        className="form-control"
        value={value.description}
        placeholder="Dynamics Qrcode for Virtual Card"
        onChange={(e) => handleDescription(e)}
      />
      <input
        type="text"
        className="form-control"
        value={value.name}
        placeholder="QRCODE Virtual Card"
        onChange={(e) => handleName(e)}
      />
      <label> Quantity </label>
      <input
        type="number"
        className="form-control"
        value={value.quantity}
        onChange={(e) => handleQuatity(e)}
      />
      <label> Unit Amont :€ </label>
      <input
        type="number"
        className="form-control"
        value={value.unit_amount.value}
        onChange={(e) => handleUnitAmount(e)}
      />
      {/* <p>
        total Item:{totalValue}
        {value.unit_amount.currency_code}{' '}
      </p> */}
      <button
        className="btn btn-primary"
        type="submit"
        onClick={(e) => handleSubmit(e)}
      >
        <i>
          <PlusIcon />
        </i>
      </button>
    </div>
  );
};
export default AddTaskForm;
